<template>
  <div class="hero-slider">
    <div class="hero-slider__wrapper"
      v-touch:swipe.left.prevent="nextSlide"
      v-touch:swipe.right.prevent="previewSlide">
      <div class="hero-slider__slide">
        <img class="hero-slider__image"
          :src="image"
          alt/>
        <Column class="hero-slider__limiter">
          <h1 class="hero-slider__title">{{title}}</h1>
          <p class="hero-slider__description">{{description}}</p>
          <Row v-if="showButtons"
            class="hero-slider__row-buttons">
            <router-link v-for="button in buttons"
              :key="button.id"
              class="grid-sm-full"
              :to="`${$locale.base}${button.link}`">
              <Button :full="$mobile" v-if="$i18n.locale === 'ru'"
                color="main">{{button.name}}
              </Button>
            </router-link>
          </Row>
        </Column>
      </div>
      <Column v-if="showNavigation" class="hero-slider__navigation">
        <Row align="center"
          justify="between">
          <Row class="hero-slider__pagination"
            :justify="$mobile ? 'center' : ''">
            <div :class="index === current ? 'hero-slider__pagination-active' : ''"
              :key="slide.id"
              class="hero-slider__pagination-step"
              v-for="(slide, index) in slides"
              v-on:click="setCurrentSlide(index)"></div>
          </Row>
          <Row align="center"
            class="hero-slider__buttons"
            v-if="current !== 0">
          </Row>
          <Row v-if="$laptop"
            class="hero-slider__buttons">
            <HeroSliderButton v-on:click.native="previewSlide"
              :params="{suffix: 'preview'}"/>
            <HeroSliderButton v-on:click.native="nextSlide"
              :params="{suffix: 'next'}"/>
          </Row>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
import HeroSliderButton from './HeroSliderButton.vue';

export default {
  name: 'HeroSlider',
  components: { HeroSliderButton },
  props: {
    params: {},
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    nextSlide() {
      if (this.current < this.slides.length - 1) {
        this.current += 1;
      } else {
        this.current = 0;
      }
    },
    previewSlide() {
      if (this.current > 0) {
        this.current -= 1;
      } else {
        this.current = this.slides.length - 1;
      }
    },
    setCurrentSlide(index) {
      this.current = index;
    },
    showButtons(slide) {
      return slide.buttons.length > 0;
    },
  },
  computed: {
    slides() {
      return this?.params;
    },
    image() {
      return this?.slides[this.current]?.image;
    },
    title() {
      return this?.slides[this.current]?.title;
    },
    description() {
      return this?.slides[this.current]?.description;
    },
    buttons() {
      return this?.slides[this.current]?.buttons;
    },
    showNavigation() {
      return this?.slides?.length > 1;
    },
  },
};
</script>
