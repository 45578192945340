<template>
  <div class="hero-slider__button"
       :class="`hero-slider__${params.suffix}`">
    <Icon class="hero-slider__button-svg"
          viewport="0 0 25 18"
          :xlink="`arrow-slider-${params.suffix}`"/>
  </div>
</template>

<script>
export default {
  name: 'HeroSliderButton',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
};
</script>
